"use strict";

let body = document.querySelector("body"),
	header = document.querySelector("header.fl-builder-content"),
	scrollPosition = 0;


// Handle mobile header's class based on scroll position including page load.
function handleMobileHeader() {
	scrollPosition = window.scrollY;

	if (scrollPosition >= 1) {
		header.classList.add("mobile-header-scrolled");
	} else {
		header.classList.remove("mobile-header-scrolled");
	}
}

window.addEventListener("load", handleMobileHeader);
window.addEventListener("scroll", handleMobileHeader);



// Add class to header when mobile menu is open
let mobileMenuToggle = document.querySelector(".fl-menu-mobile-toggle");

function detectMobileHeader() {
	setTimeout(function(){
		if (mobileMenuToggle.classList.contains("fl-active")) {
			header.classList.add("mobile-header-open");
			body.classList.add("no-scroll");
		} else {
			header.classList.remove("mobile-header-open");
			body.classList.remove("no-scroll");
		}
	}, 100);
}

mobileMenuToggle.addEventListener("click", detectMobileHeader);



// Mobile styles based on header height
let mobileMenu = document.querySelector("#menu-header"),
	menuItemsNav = document.querySelector(".col-menu-nav"),
	windowHeight = window.innerHeight,
	headerHeight = header.offsetHeight;
const mediaQuery = window.matchMedia("(max-width: 992px)");

function mobileHeaderHeightOffsets(x) {
	if (x.matches) {
		headerHeight =  header.offsetHeight;
		windowHeight = window.innerHeight;
		var menuLinksHeight = document.querySelector(".site__header__cta").offsetHeight;
		
		// Set height for mobile menu
		mobileMenu.style.height = (windowHeight - headerHeight - menuLinksHeight + 1) + "px";
		// Push content down, since header is fixed
		document.querySelector("#fl-main-content").style.marginTop = headerHeight + "px";

	} else {
		// Don't push content down, since header is relative
		document.querySelector("#fl-main-content").style.marginTop = "0px";
		// Set height for mobile menu
		mobileMenu.style.height = "auto";
	}

	// Set top value for sticky food menu nav
	if (menuItemsNav) {
		menuItemsNav.style.top = headerHeight + document.querySelector(".content-switcher").offsetHeight + "px";
	}

	// Push .content-switcher down
	if(document.querySelector(".content-switcher") !== null) {
		if(document.body.classList.contains("admin-bar")) {
			var adminBarHeight = document.querySelector("#wpadminbar").offsetHeight;
			document.querySelector(".content-switcher").style.top = headerHeight + adminBarHeight + "px";
		} else {
			document.querySelector(".content-switcher").style.top = headerHeight + "px";
		}
	}
}

window.addEventListener("load", mobileHeaderHeightOffsets(mediaQuery));
mediaQuery.addEventListener("change", mobileHeaderHeightOffsets);



// Food menu nav accordion
var acc = document.querySelectorAll(".menuitems_nav .category"),
	i;

if (acc.length >= 1) {
	for (i = 0; i < acc.length; i++) {
		acc[i].querySelector(".category-label").addEventListener("click", function() {
//			// Only one accordion open at once (can't close unless open another)
//			acc.forEach((e) => {
//				e.classList.remove("active");
//				//e.querySelector(".submenu").style.maxHeight = null;
//			});
//
//			// Add active class to clicked accordion
//			this.classList.add("active");
			
			// No more than one accordion open at once (can close to have none open)
			if (!this.parentNode.classList.contains("active")) {
				// Click on inactive
				acc.forEach((e) => {
					e.classList.remove("active");
					e.querySelector(".submenu").style.maxHeight = null;
				});
				this.parentNode.classList.add("active");
			} else {
				// Click on active
				this.parentNode.classList.remove("active");
			}

			// Expand content for clicked accordion
			var submenu = this.parentNode.querySelector(".submenu");
			if (submenu.style.maxHeight) {
				submenu.style.maxHeight = null;
			} else {
				submenu.style.maxHeight = submenu.scrollHeight + "px";
			}

			// Add scrolling if column is too tall
			setTimeout(menuItemsNavScrollCheck, 400);
		});
	}
	// Expand first accordion
	window.onload = function () {
		acc[0].classList.add("active");

		if (document.querySelector(".category.active")) {
			document.querySelector(".category.active .submenu").style.maxHeight = document.querySelector(".category.active .submenu").scrollHeight + "px";
		}
	};
}

// Food menu PDF accordion
var accPDF = document.querySelectorAll(".menuitems_pdfs .category"),
	i;

if (accPDF.length >= 1) {
	for (i = 0; i < accPDF.length; i++) {
		accPDF[i].querySelector(".category-label").addEventListener("click", function() {
			
			// No more than one accordion open at once (can close to have none open)
			if (!this.parentNode.classList.contains("active")) {
				// Click on inactive
				accPDF.forEach((e) => {
					e.classList.remove("active");
					e.querySelector(".submenu").style.maxHeight = null;
				});
				this.parentNode.classList.add("active");
			} else {
				// Click on active
				this.parentNode.classList.remove("active");
			}

			// Expand content for clicked accordion
			var submenu = this.parentNode.querySelector(".submenu");
			if (submenu.style.maxHeight) {
				submenu.style.maxHeight = null;
			} else {
				submenu.style.maxHeight = submenu.scrollHeight + "px";
			}

			// Add scrolling if column is too tall
			setTimeout(menuItemsNavScrollCheck, 400);
		});
	}
}

// Add scrolling to food menu nav if it's too tall (out of view)
function menuItemsNavScrollCheck() {
	headerHeight =  header.offsetHeight;
	windowHeight = window.innerHeight;
	var menuItemsNavCol = document.querySelector(".col-menu-nav");
	var menuItemsNavColHeight = menuItemsNavCol.offsetHeight;
	var contentSwitcherHeight = document.querySelector(".content-switcher").offsetHeight;
	if(document.body.classList.contains("admin-bar")) {
		var adminBarHeight = document.querySelector("#wpadminbar").offsetHeight;
	} else {
		var adminBarHeight = 0;
	}
	var viewportOffset = menuItemsNavCol.getBoundingClientRect();
	var menuItemsNavColTop = viewportOffset.top;
	//var availableSpace = windowHeight - adminBarHeight - headerHeight - contentSwitcherHeight;
	var availableSpace = windowHeight - menuItemsNavColTop;

	if(menuItemsNavColHeight >= availableSpace) {
		// If stuck (from being sticky)
		if(menuItemsNavColTop == parseInt(menuItemsNavCol.style.top, 10)) {
			// Set height of menuItemsNavCol
			menuItemsNavCol.style.height = availableSpace + "px";
			// Add scroll class
			menuItemsNavCol.classList.add("scroll");
		}
	} else {
		// Remove height of menuItemsNavCol
		menuItemsNavCol.style.height = "";
		// Remove scroll class
		menuItemsNavCol.classList.remove("scroll");
	}
}



// Catering form, show hide sold out days banner based on location selection
if (document.querySelector(".catering-location")) {
	document.querySelectorAll(".catering-location ul li").forEach(function (choice) {
		choice.addEventListener("click", function (e) {
			// Remove active class from all banners
			document.querySelectorAll(".soldOutDaysForm").forEach(function (banner) {banner.classList.remove("soldOutDays--active");});
			// Add active class to selected banner if the banner exists
			if (document.querySelector(".soldOutDays__location__" + choice.childNodes[0].value.toLowerCase())) {
				document.querySelector(".soldOutDays__location__" + choice.childNodes[0].value.toLowerCase()).classList.add("soldOutDays--active");
			}
			// Scroll to top of page, so user can see the banner
			window.scrollTo({ top: 0, behavior: "smooth" });
		});
	});
}


// Catering form, immediately continue to next page after clicking one of the multiple choice boxes
// Locations: ul#wpforms-1064-field_24
// Event Types: ul#wpforms-1064-field_26, ul#wpforms-1064-field_27, ul#wpforms-1064-field_28, ul#wpforms-1064-field_29
// Services: ul#wpforms-1064-field_58, ul#wpforms-1064-field_59, ul#wpforms-1064-field_64, ul#wpforms-1064-field_65, ul#wpforms-1064-field_66, ul#wpforms-1064-field_67
if (document.querySelector(".catering_form")) {
	document.querySelectorAll(".wpforms-field-radio:not(#wpforms-1064-field_25-container) ul li input").forEach(function (item) {
		item.addEventListener("click", function (e) {
			item.closest(".wpforms-page").querySelector(".wpforms-page-next").click();
		});
	});
}


// UserWay Accessibility, align to bottom until reach footer to make room for footer social links
window.onscroll = function() {
	if (document.querySelector(".uwy")) {
		document.querySelector(".uwy").classList.remove("nudge");
	}
	if (document.querySelector("#fb-root")) {
		document.querySelector("#fb-root").classList.remove("nudge");
	}
    const rest = document.documentElement.scrollHeight - document.documentElement.scrollTop;
    if (Math.abs(document.documentElement.clientHeight - rest) < document.querySelector(".site__footer").getBoundingClientRect().height) {
		if (document.querySelector(".uwy")) {
			document.querySelector(".uwy").classList.add("nudge");
		}
		if (document.querySelector("#fb-root")) {
			document.querySelector("#fb-root").classList.add("nudge");
		}
	}
};



// Events calendar tweaks
if (document.querySelector("body").classList.contains("post-type-archive-tribe_events")) {
	if (document.querySelector(".tribe-events-view--month script[data-js='tribe-events-view-data']")) {
		triggerResizeEvent();
	}
}
function triggerResizeEvent() {
	document.querySelectorAll(".location__content.calendar-shortcode").forEach(function (el) {
		el.style.display = "none";
	});
}



// Move Amazon Pay button from above checkout form to other payment gateways
jQuery(".wc-amazon-checkout-message").prependTo(".moveAmazonPayHere");



// Smooth scrolling tweaks with code
// https://docs.wpbeaverbuilder.com/beaver-builder/advanced-builder-techniques/smooth-scrolling-tweaks-with-code
if (menuItemsNav) {
	jQuery(function () {
		if ("undefined" != typeof FLBuilderLayoutConfig) {
			FLBuilderLayoutConfig.anchorLinkAnimations.duration = 1000;
			FLBuilderLayoutConfig.anchorLinkAnimations.easing = "swing";
			FLBuilderLayoutConfig.anchorLinkAnimations.offset = headerHeight + document.querySelector(".content-switcher").offsetHeight;
		}
	});
}


